<template>
  <div class="flex flex-col">
    <div class="grid grid-cols-12 gap-4">
      <div
        class="
          flex
          py-4
          flex-col
          text-left
          space-y-3
          col-span-3
          bg-white bg-opacity-80
          px-4
          rounded
        "
      >
        <search-customers v-on:customer="customerChanged"></search-customers>
        <label class="w-full flex flex-col items-start">
          <span class="text-gray-700">{{ $t("transactionMode") }}</span>
          <select
            name="delimiter"
            v-model="transactionMode"
            class="
              block
              w-full
              mt-1
              rounded-md
              bg-gray-100
              border-transparent
              focus:border-gray-500 focus:bg-white focus:ring-0
            "
          >
            <option value="">{{ $t("select") }}</option>
            <option
              :key="index"
              v-for="(mode, index) in transactionModes"
              :value="mode.id"
            >
              {{ $t(mode.name) }}
            </option>
          </select>
        </label>

        <label class="block">
          <span class="text-gray-700">{{ $t("fromDate") }}</span>
          <DatePicker v-model="fromDate" :masks="masks">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue"
                v-on="inputEvents"
                type="text"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              />
            </template>
          </DatePicker>
        </label>
        <label class="block">
          <span class="text-gray-700">{{ $t("toDate") }}</span>
          <DatePicker v-model="toDate" :masks="masks">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue"
                v-on="inputEvents"
                type="text"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              />
            </template>
          </DatePicker>
        </label>
        <l-button
          @click="fetchRechargeSummary"
          class="bg-brand rounded text-white text-center text-xl"
          :text="$t('filter')"
        >
        </l-button>
        <l-button
          type="danger"
          class="bg-red-500 rounded text-white text-center text-xl"
          :text="$t('clearAll')"
        >
        </l-button>
      </div>
      <div
        class="
          col-span-9
          flex flex-col
          items-start
          py-4
          w-full
          overflow-y-scroll
        "
      >
        <l-table>
          <template #thead>
            <l-t-head>
              <l-tr>
                <l-th> {{ $t("date") }} </l-th>
                <l-th> {{ $t("transactionMode") }} </l-th>
                <l-th> {{ $t("amount") }} </l-th>
                <l-th> {{ $t("credit") }} </l-th>
                <l-th> {{ $t("balance") }} </l-th>
                <l-th> {{ $tc("remark", 2) }} </l-th>
              </l-tr>
            </l-t-head>
          </template>
          <template #tbody>
            <l-t-body>
              <l-tr
                :key="`${index}`"
                v-for="(item, index) in rechargeSummaryReport"
              >
                <l-td>
                  <p>{{ $filters.dateFormat(item.created) }}</p>
                </l-td>
                <l-td>
                  <p class="">
                    {{ $t(item.transaction_mode) }}
                  </p>
                </l-td>
                <l-td>
                  <p>{{ $filters.euroFormat(item.amount) }}</p>
                </l-td>
                <l-td>
                  <p>{{ $filters.euroFormat(item.credit) }}</p>
                </l-td>
                <l-td>
                  <p>{{ $filters.euroFormat(item.balance) }}</p>
                </l-td>
                <l-td>
                  <p class="whitespace-normal">{{ item.remarks }}</p>
                </l-td>
              </l-tr>
            </l-t-body>
          </template>
        </l-table>
      </div>
    </div>
    <div class="items-center flex justify-center" v-if="totalPages > 0">
      <l-pagination
        :max="totalPages"
        :value="activatePage"
        @input="pageUpdated"
      ></l-pagination>
    </div>
  </div>
</template>

<script>
import LButton from "@/components/custom/LButton";
import LTable from "@/components/custom/table/LTable";
import LTHead from "@/components/custom/table/LTHead";
import LTr from "@/components/custom/table/LTr";
import LTd from "@/components/custom/table/LTd";
import LTh from "@/components/custom/table/LTh";
import LTBody from "@/components/custom/table/LTBody";
import { mapGetters } from "vuex";
import LPagination from "@/components/custom/pagination/LPagination";
import SearchCustomers from "@/components/customers/SearchCustomers";
import { DatePicker } from "v-calendar";
import moment from "moment";

export default {
  name: "RechargeSummaryReport",
  components: {
    SearchCustomers,
    LPagination,
    LTable,
    LTHead,
    LButton,
    LTr,
    LTh,
    LTd,
    LTBody,
    DatePicker,
  },
  data() {
    return {
      activeTab: 0,
      activatePage: 1,
      fromDate: new Date(),
      toDate: new Date(),
      masks: {
        input: "DD.MM.YYYY",
      },
      selectedCustomer: null,
      transactionMode: "",
    };
  },
  computed: {
    ...mapGetters([
      "transactionModes",
      "rechargeSummaryFilters",
      "rechargeSummaryReport",
    ]),
    totalPages() {
      let total =
        this.rechargeSummaryFilters.total / this.rechargeSummaryFilters.limit;
      if (total) {
        return Math.ceil(total);
      } else {
        return 0;
      }
    },
  },
  methods: {
    prepareGetData() {
      let data = {
        page: this.activatePage - 1,
      };
      if (this.fromDate) {
        data["from_date"] = moment(this.fromDate).format("YYYY-MM-DD");
      }
      if (this.toDate) {
        data["to_date"] = moment(this.toDate).format("YYYY-MM-DD");
      }
      if (this.selectedCustomer) {
        data["customer_id"] = this.selectedCustomer.customer_id;
      }
      return data;
    },
    customerChanged(value) {
      this.selectedCustomer = value;
    },
    fetchRechargeSummary() {
      this.$store.dispatch(
        "fetchRechargeSummaryReportList",
        this.prepareGetData()
      );
    },
    pageUpdated(value) {
      this.activatePage = value;
      this.fetchRechargeSummary();
    },
  },
  mounted() {
    this.fetchRechargeSummary();
  },
};
</script>

<style scoped></style>
