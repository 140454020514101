<template>
  <div class="flex flex-col">
    <div class="grid grid-cols-12 gap-4">
      <div
        class="
          flex
          py-4
          flex-col
          text-left
          space-y-3
          col-span-3
          bg-white bg-opacity-80
          px-4
          rounded
        "
      >
        <search-customers v-on:customer="customerChanged"></search-customers>

        <label class="block">
          <span class="text-gray-700">{{ $t("fromDate") }}</span>
          <DatePicker v-model="fromDate" :masks="masks">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue"
                v-on="inputEvents"
                type="text"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              />
            </template>
          </DatePicker>
        </label>
        <label class="block">
          <span class="text-gray-700">{{ $t("toDate") }}</span>
          <DatePicker v-model="toDate" :masks="masks">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue"
                v-on="inputEvents"
                type="text"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              />
            </template>
          </DatePicker>
        </label>
        <l-button
          @click="fetchSalesReport"
          class="bg-brand rounded text-white text-center text-xl"
          :text="$t('filter')"
        >
        </l-button>
        <l-button
          type="danger"
          class="bg-red-500 rounded text-white text-center text-xl"
          :text="$t('clearAll')"
        >
        </l-button>
      </div>
      <div class="col-span-9 flex flex-col items-start py-4 w-full">
        <div class="flex flex-row py-2 space-x-4 justify-between w-full">
          <table class="border-collapse border-0 border-green-800 ...">
            <thead>
              <tr class="text-gray-500">
                <th class="px-4 font-medium">{{ $t("totalBuyingAmount") }}</th>
                <th class="px-4 font-medium">{{ $t("totalSaleAmount") }}</th>
                <th class="px-4 font-medium">{{ $t("profit") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-bold text-brand text-2xl py-2">
                  <p>
                    {{ $filters.euroFormat(salesReportRaw.total_buying_price) }}
                  </p>
                </td>
                <td class="font-bold text-brand text-2xl py-2">
                  <p>
                    {{
                      $filters.euroFormat(salesReportRaw.total_selling_price)
                    }}
                  </p>
                </td>
                <td class="font-bold text-brand text-2xl py-2">
                  <p>{{ $filters.euroFormat(salesReportRaw.total_profit) }}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-center">
            <l-button
              @click="downloadSalesReport"
              class="bg-brand rounded text-white"
              :text="$t('download')"
            >
              <template v-slot:icon>
                <DownloadIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              </template>
            </l-button>
          </div>
        </div>
        <div class="w-full overflow-y-scroll">
          <l-table>
            <template #thead>
              <l-t-head>
                <l-tr>
                  <l-th> {{ $t("id") }} </l-th>
                  <l-th> {{ $t("soldDate") }} </l-th>
                  <l-th> {{ $t("customer") }} </l-th>
                  <l-th> {{ $t("product") }} </l-th>
                  <l-th> {{ $t("quantity") }} </l-th>
                  <l-th> {{ $t("buyingPrice") }} </l-th>
                  <l-th> {{ $t("profit") }} </l-th>
                  <l-th> {{ $t("sellingPrice") }} </l-th>
                  <l-th> {{ $t("action") }} </l-th>
                </l-tr>
              </l-t-head>
            </template>
            <template #tbody>
              <l-t-body>
                <template v-for="(item, index) in salesReport">
                  <template v-if="item.has_multi_records">
                    <l-tr
                      :key="rIndex"
                      v-for="(record, rIndex) in item.records"
                    >
                      <l-td>
                        <p v-if="rIndex === 0">{{ record.customer_id }}</p>
                      </l-td>
                      <l-td>
                        <p v-if="rIndex === 0" class="">
                          {{ $filters.dateFormat(record.sold_date) }}
                        </p>
                      </l-td>
                      <l-td>
                        <p v-if="rIndex === 0">{{ record.customer }}</p>
                      </l-td>
                      <l-td>
                        <p>
                          {{ record.product }} -
                          {{ $filters.euroFormat(record.denomination) }}
                        </p>
                      </l-td>
                      <l-td>
                        <p class="font-bold">{{ record.quantity }}</p>
                      </l-td>
                      <l-td>
                        <p>{{ $filters.euroFormat(record.buying_price) }}</p>
                      </l-td>
                      <l-td>
                        <p>{{ $filters.euroFormat(record.profit) }}</p>
                      </l-td>
                      <l-td>
                        <p>
                          {{ $filters.euroFormat(record.selling_price) }}
                        </p>
                      </l-td>
                      <l-td>
                        <l-button
                          v-if="record.has_reclaim"
                          type="danger"
                          :text="$t('reclaim')"
                        ></l-button>
                      </l-td>
                    </l-tr>
                    <l-tr :key="`${index}-total`">
                      <l-td> </l-td>
                      <l-td> </l-td>
                      <l-td> </l-td>
                      <l-td>
                        <p class="text-brand font-medium">{{ $t("total") }}</p>
                      </l-td>
                      <l-td>
                        <p class="font-bold">{{ item.total_quantity }}</p>
                      </l-td>
                      <l-td>
                        <p>
                          {{ $filters.euroFormat(item.total_buying_price) }}
                        </p>
                      </l-td>
                      <l-td>
                        <p>{{ $filters.euroFormat(item.total_profit) }}</p>
                      </l-td>
                      <l-td>
                        <p>
                          {{ $filters.euroFormat(item.total_selling_price) }}
                        </p>
                      </l-td>
                      <l-td> </l-td>
                    </l-tr>
                  </template>
                  <l-tr :key="`${index}-else`" v-else>
                    <l-td>
                      <p>{{ item.customer_id }}</p>
                    </l-td>
                    <l-td>
                      <p class="">
                        {{ $filters.dateFormat(item.sold_date) }}
                      </p>
                    </l-td>
                    <l-td>
                      <p>{{ item.customer }}</p>
                    </l-td>
                    <l-td>
                      <p>
                        {{ item.product }} -
                        {{ $filters.euroFormat(item.denomination) }}
                      </p>
                    </l-td>
                    <l-td>
                      <p class="font-bold">{{ item.quantity }}</p>
                    </l-td>
                    <l-td>
                      <p>{{ $filters.euroFormat(item.buying_price) }}</p>
                    </l-td>
                    <l-td>
                      <p>{{ $filters.euroFormat(item.profit) }}</p>
                    </l-td>
                    <l-td>
                      <p>
                        {{ $filters.euroFormat(item.selling_price) }}
                      </p>
                    </l-td>
                    <l-td>
                      <l-button
                        v-if="item.has_reclaim"
                        type="danger"
                        :text="$t('reclaim')"
                      ></l-button>
                    </l-td>
                  </l-tr>
                </template>
              </l-t-body>
            </template>
          </l-table>
        </div>
      </div>
    </div>
    <div class="items-center flex justify-center" v-if="totalPages > 0">
      <l-pagination
        :max="totalPages"
        :value="activatePage"
        @input="pageUpdated"
      ></l-pagination>
    </div>
  </div>
</template>

<script>
import { DownloadIcon } from "@heroicons/vue/outline";
import LButton from "@/components/custom/LButton";
import LTable from "@/components/custom/table/LTable";
import LTHead from "@/components/custom/table/LTHead";
import LTr from "@/components/custom/table/LTr";
import LTd from "@/components/custom/table/LTd";
import LTh from "@/components/custom/table/LTh";
import LTBody from "@/components/custom/table/LTBody";
import { mapGetters } from "vuex";
import LPagination from "@/components/custom/pagination/LPagination";
import SearchCustomers from "@/components/customers/SearchCustomers";
import { DatePicker } from "v-calendar";
import moment from "moment";

export default {
  name: "SalesReport",
  components: {
    SearchCustomers,
    LPagination,
    LTable,
    LTHead,
    LButton,
    DownloadIcon,
    LTr,
    LTh,
    LTd,
    LTBody,
    DatePicker,
  },
  data() {
    return {
      activeTab: 0,
      activatePage: 1,
      fromDate: new Date(),
      toDate: new Date(),
      masks: {
        input: "DD.MM.YYYY",
      },
      selectedCustomer: null,
    };
  },
  computed: {
    ...mapGetters(["salesReport", "salesReportFilters", "salesReportRaw"]),
    totalPages() {
      let total = this.salesReportFilters.total / this.salesReportFilters.limit;
      if (total) {
        return Math.ceil(total);
      } else {
        return 0;
      }
    },
  },
  methods: {
    prepareGetData() {
      let data = {
        page: this.activatePage - 1,
      };
      if (this.fromDate) {
        data["from_date"] = moment(this.fromDate).format("YYYY-MM-DD");
      }
      if (this.toDate) {
        data["to_date"] = moment(this.toDate).format("YYYY-MM-DD");
      }
      if (this.selectedCustomer) {
        data["customer_id"] = this.selectedCustomer.customer_id;
      }
      return data;
    },
    customerChanged(value) {
      this.selectedCustomer = value;
    },
    fetchSalesReport() {
      this.$store.dispatch("fetchSalesReportsList", this.prepareGetData());
    },
    downloadSalesReport() {
      const params = this.prepareGetData();
      params["download"] = 1;
      this.$store.dispatch("fetchSalesReportsList", params).then((data) => {
        this.$filters.downloadCSV(data, "sales_report.csv");
        // const hiddenElement = document.createElement("a");
        // hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`;
        // hiddenElement.target = "_blank";
        // hiddenElement.download = "customers.csv";
        // hiddenElement.click();
      });
    },
    pageUpdated(value) {
      this.activatePage = value;
      this.fetchSalesReport();
    },
  },
  mounted() {
    this.fetchSalesReport();
  },
};
</script>

<style scoped></style>
